import {bindEvent} from '../eventHandlers/eventHandler';
var _throttle = require('lodash.throttle');

export function init() {

	function scrollInit() {

		if($(window).scrollTop() > 20) {
			$(".header").addClass("small-header");	
		} else {
			$(".header").removeClass("small-header");	
		}

	}

	bindEvent(window, 'scroll', _throttle(scrollInit, 0));
};