export function init() {

	var $map = document.getElementById('map');

	window.callInitGoogleOptions = function() {
		initGoogleOptions();
	};

	var loadAsyncScript = function() {
		var s = document.createElement('script');

		s.src = '//maps.googleapis.com/maps/api/js?sensor=false&key=AIzaSyCbGF5HyrwNde3Jj_iEqgkvA3LNeZ9GNJI&callback=callInitGoogleOptions';
		document.body.appendChild(s);
	};

	$(window).bind('load scroll', function(){
		var mapCanvasOffset = $('#map').offset().top - $(window).scrollTop() - 1000;

		if( $(window).scrollTop() > mapCanvasOffset && !$('body').is('.map-loaded') ) {

			$(document).trigger('load-map');
		}
	});
	
	$(document).on('load-map', function(){
		$('body').addClass('map-loaded');
		
		loadAsyncScript();
	});

	function initGoogleOptions() {
		// var is_mobile = $(window).width() < 700;

		var myLatLng = {lat: 60.11021, lng: 24.7385082},
			myOptions = {
	        	// zoom: is_mobile ? 9 : 11,
	        	zoom: 7,
	        	center: myLatLng,
	        	scrollwheel: false,
                scaleControl: false,
                disableDefaultUI: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP
	        },
	        map = new google.maps.Map($map,myOptions);

	  	// google.maps.event.addListener(map, "zoom_changed", function() {
	   //      map.setCenter({lat: 52.2222944, lng: 19.336074});
	   //  });

	  	map.panBy(150, -200);

	  	var iconBase =
            'http://ihg.finland.pl/wp-content/themes/ihg/img/';

        var icons = {
          holidayinnhelsinkicitycentre: {
            icon: iconBase + 'marker.svg',
          },
          crowneplazahelsinkihesperia: {
            icon: iconBase + 'marker.svg'
          },
          indigohelsinkiboulevard: {
            icon: iconBase + 'marker.svg'
          },
          holidayinnhelsinkiwestruoholahti: {
            icon: iconBase + 'marker.svg'
          },
          holidayinnhelsinkivantaaairport: {
            icon: iconBase + 'marker.svg'
          }, 
          holidayinntamperecentralstation: {
            icon: iconBase + 'marker.svg'
          }, 
          holidayinnhelsinkiexpo: {
            icon: iconBase + 'marker.svg'
          }
        };

	  	var features = [
          {
            position: new google.maps.LatLng(60.1799353, 24.926792),
            url: 'https://finland.ihg.com/hotellit/crowne-plaza-helsinki-hesperia/',
            type: 'crowneplazahelsinkihesperia'
          }, {
            position: new google.maps.LatLng(60.1639125, 24.9366602),
            url: 'https://finland.ihg.com/hotellit/hotel-indigo-helsinki-boulevard/',
            type: 'indigohelsinkiboulevard'
          }, {
            position: new google.maps.LatLng(60.1726996, 24.939083),
            url: 'https://finland.ihg.com/hotellit/holiday-inn-helsinki-city-centre/',
            type: 'holidayinnhelsinkicitycentre'
          }, {
            position: new google.maps.LatLng(60.1650647, 24.9094803),
            url: 'https://finland.ihg.com/en/hotels/holiday-inn-helsinki-west-ruoholahti/',
            type: 'holidayinnhelsinkiwestruoholahti'
          }, {
            position: new google.maps.LatLng(60.2952116, 24.971869),
            url: 'https://finland.ihg.com/hotellit/holiday-inn-helsinki-vantaa-airport/',
            type: 'holidayinnhelsinkivantaaairport'
          }, {
            position: new google.maps.LatLng(61.5000429, 23.7720184),
            url: 'https://finland.ihg.com/hotellit/holiday-inn-tampere-central-station/',
            type: 'holidayinntamperecentralstation'
          }, {
            position: new google.maps.LatLng(60.201791, 24.9370241),
            url: 'https://finland.ihg.com/hotellit/holiday-inn-helsinki-expo/',
            type: 'holidayinnhelsinkiexpo'
          }
        ]


	  	for (var i = 0; i < features.length; i++) {
          var marker = new google.maps.Marker({
          	url: features[i].url,
            position: features[i].position,
            // icon: {url: icons[features[i].type].icon, scaledSize: new google.maps.Size(30, 30), origin: new google.maps.Point(0,0), anchor: new google.maps.Point(0, 0)},
            map: map
          });

	        google.maps.event.addListener(marker, 'click', function(a) {
			  window.location.href = this.url;
			});
        };
	}
	
}