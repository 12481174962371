export function init() {
	var Popup = (function () {

		// Local variables
		// s == settings
		var s = {},
			isExitPopupShown = false,
			previousY = 0,
			isPopupVisible = false;

		var close = function() {
			s.popupEl.addClass('visuallyhidden');

			isPopupVisible = false;
		};

		var show = function() {
			// If another popup is already visible - don't show the next one
			if (!isPopupVisible) {

				// Show the popup
				s.popupEl.removeClass('visuallyhidden');

				isPopupVisible = true;
			}
		};

		var init = function(config) {
			s = config;

			// Handle popup close events

			// On "x" click
			s.closeEl.on('click', close);

			// But do nothing when clicked inside popup
			s.popupEl.on('click', function (e) {
				e.stopPropagation();
			});

			window.setTimeout(function(){ 
				// First check, if localStorage is supported.
				if (window.localStorage) {
					// Get the expiration date of the previous popup.
					var nextPopup = localStorage.getItem( 'setNewsletter' );

					if (nextPopup > new Date()) {
						return;
					}

					// Store the expiration date of the current popup in localStorage.
					var expires = new Date();
					expires = expires.setHours(expires.getHours() + 24);

					localStorage.setItem( 'setNewsletter', expires );
				}

				show();
			}, 0);
		};

		return {
			init: init
		};

	})();

	Popup.init({
		popupEl: $('.popup--exit'),
		closeEl: $('.popup--exit').find('.close')
	});
}
